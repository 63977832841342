module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","cs"],"defaultLanguage":"cs","fallbackLanguage":"cs","siteUrl":"https://www.kapitalovypruvodce.cz/","redirect":false,"i18nextOptions":{"defaultNS":"common","returnNull":false,"lowerCaseLng":true,"interpolation":{"escapeValue":false}},"pages":[{"matchPath":["/:lang?/co-je-kapitalovy-trh","/:lang?/slovnicek-pojmu","/:lang?/jak-na-to/jak-vyuzit-web","/:lang?/dluhove-nastroje/:slug","/:lang?/kapitalove-nastroje/:slug"],"getLanguageFromPath":true},{"matchPath":["/404","/404.html"],"exludeLanguages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo.svg","icon_options":{"purpose":"any maskable"},"start_url":"/","lang":"cs","name":"Kapitálový průvodce","short_name":"Kapitálový průvodce","display":"standalone","background_color":"#8D1332","theme_color":"#FFF","localize":[{"start_url":"/en/","lang":"en","name":"Capital Guide","short_name":"Capital Guide"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2e41cde752958379e675d19d0aac0f82"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-DJK2GLE9VL"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":920,"quality":85,"withWebp":true,"withAvif":true,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
